require('./assets/styles/_main.scss');
require('!style-loader!css-loader!video.js/dist/video-js.css');
require('!style-loader!css-loader!owl.carousel2/dist/assets/owl.carousel.css');
require('!style-loader!css-loader!owl.carousel2/dist/assets/owl.theme.default.css');

import $ from 'jquery';
import jquery from 'jquery';
import Video from 'video.js';
import 'owl.carousel2';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/modal';
import './assets/js/goTop';
import './assets/js/dropdown';
import './assets/js/sideNav';
import './assets/js/header';

window.videojs = Video;

jquery(() => {
  console.log('jQuery, popper, video.js, bootstrap 4, owl.carousel');

  function load_next_thumbnails() {
    var nextPageUrl = $('.pagination .next a').attr('href');
    if (!!nextPageUrl) {
      var date = new Date();
      var timestamp = date.getTime();
      nextPageUrl += '&ajax_load=' + timestamp;
      $('.pagination').remove();
      $('#spinner-bottom').show();
      $.get(nextPageUrl).then(function (html) {
        const content = $(html).find('#thumbnails');
        content.find('.pagination').hide();
        $('#content').append(content);
        $('#spinner-bottom').hide();
      }, function () {
        $('#spinner-bottom').hide();
      });
    } else {
      $(window).off("wheel");
    }
  }

  $('.template-big_thumbnails, .template-small_thumbnails').each(function () {
    $('.pagination').hide();

    var wheelTimeout;
    $(window).on("wheel", function () {
      var close_to_bottom = ($(window).scrollTop() + $(window).height() >= $(document).height() - 10);
      if (close_to_bottom && !wheelTimeout) {
        wheelTimeout = setTimeout(function () {
          load_next_thumbnails();
          wheelTimeout = null;
        }, 100);
      }
    });
  });

  if ($("#video-player").length > 0) {

    var player = videojs('video-player', {
        controlBar: {
          pictureInPictureToggle: false,
          remainingTimeDisplay: false
        }
      });

  }

});
