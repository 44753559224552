import $ from "jquery";

$(function() {
// var header = $(".header");
// //var mainStart = $(".main").offset().top;
// $(window).scroll(function() {
//   var scroll = $(window).scrollTop();
//   var height = ($(".header").outerHeight(true));
//   if (scroll > 5 ) {
//     header.addClass('fixed-top');
//     $('main').addClass('smoothed').css('padding-top',(height+5)+'px');
//   } else {
//     header.removeClass("fixed-top");
//     $('main').removeClass('smoothed').css('padding-top','5px');
//   }
// });
});
