import $ from "jquery";

function goTopLeft() {
  var WindowWidth = $(window).width();
  var pos = (WindowWidth - 70) / 2;

  $(".gotop").css({
    left: pos
  });
}

// gestion btn go to top
$('.gotop').hide();

$('.gotop').click(function () {
  $('HTML, BODY').animate({ scrollTop: $('#site').offset().top }, 1500, 'easeOutCirc', function () {
    $('.gotop').fadeOut();
  });
  return false;
});

// scroll & btn gotop
$(window).scroll(function () {
  var t = $(this).scrollTop();
  //affichage du bouton gotop
  if (t > 300) {
    $(".gotop").fadeIn();
  } else {
    $(".gotop").fadeOut();
  }
});

goTopLeft();

//fix for ie8 and before bug on resize()
function onWindowResize(callback) {
  var originalWidth = $(window).width(),
    originalHeight = $(window).height();

  $(window).resize(function () {
    var newWidth = $(window).width(),
      newHeight = $(window).height();

    if (newWidth !== originalWidth || newHeight !== originalHeight) {
      originalWidth = newWidth;
      originalHeight = newHeight;
      callback();
    }
  });
}

onWindowResize(function () {
  goTopLeft();
});

// Add class active to siblings before the last active
//$('.nav-item.active').prevAll().addClass('active')

// Add parent state to pending demand accordion on homepage
var registration = document.getElementById("registration");
if(registration){
  let registrationBtn = document.querySelector('.opening-more');
  let registrationList = document.querySelector('.registration_list');
  registrationBtn.addEventListener('click', () => {
    registrationList.classList.toggle('opened');
  });
};

